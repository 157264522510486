const config = {
  debug: process.env.NODE_ENV === 'development',
  siteName: process.env.NEXT_PUBLIC_ENV_SITE_NAME || 'Galllery',
  siteUrl: process.env.NEXT_PUBLIC_ENV_SITE_URL || 'https://www.galllery.co',
  projectKey: 'g4lll3ry',
  graphQlUri:
    process.env.NEXT_PUBLIC_ENV_GRAPHQL_URI ||
    'https://www.galllery.co/api/graphql',
  graphQlUriDev: 'http://localhost:3000/api/graphql',

  email: 'no-reply@galllery.co',
  telephone: '+44123123123',
  address: 'London, UK',
  shortcutLink:
    'https://www.icloud.com/shortcuts/64e20bf470e9414f8820e23707b91375',

  googleTrackingId: 'G-25YLL7VYHL',
};

export default config;
