import React from 'react';
import { createGlobalStyle } from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import tw, { GlobalStyles as BaseStyles } from 'twin.macro';

export const theme = {
  colors: {
    primary: '#0070f3',
  },
};

const CustomStyles = createGlobalStyle`
  body {
    ${tw`antialiased`}
  }
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
