/* eslint-disable react/no-danger */
/* eslint-disable no-undef */
import { ThemeProvider } from 'styled-components';
import SimpleReactLightbox from 'simple-react-lightbox';
import { ThemeProvider as NextThemeProvider } from 'next-themes';
import { StoreProvider } from 'easy-peasy';
import { ZeiqProvider } from '@zeiq/web';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ApolloProvider } from '@apollo/client/react';
import { DefaultSeo } from 'next-seo';
import Router from 'next/router';
import NProgress from 'nprogress';
import Head from 'next/head';

// eslint-disable-next-line import/no-extraneous-dependencies
import GlobalStyles, { theme } from '../components/global/GlobalStyles';
import apolloClient from '../utils/apolloClient';
import withReduxStore from '../utils/with-redux-store';
import config from '../utils/config';
import * as gtag from '../utils/gtag';
import '../styles/nprogress.css';

Router.events.on('routeChangeStart', (url) => {
  console.log(`Loading: ${url}`);
  NProgress.start();
});
Router.events.on('routeChangeComplete', (url) => {
  NProgress.done();
  gtag.pageview(url);
});
Router.events.on('routeChangeError', () => NProgress.done());

function MyApp({ Component, pageProps, reduxStore }) {
  return (
    <>
      <GlobalStyles />
      <Head>
        <script async src="/scripts/main.js" />
        {/* Global Site Tag (gtag.js) - Google Analytics */}
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${gtag.GA_TRACKING_ID}', {
                    page_path: window.location.pathname,
                  });
                `,
          }}
        />
        {/* Global Site Tag (gtag.js) - Google Analytics */}
      </Head>
      <DefaultSeo
        titleTemplate={`%s | ${config.siteName}`}
        description="Free Images Gallery Builder"
        openGraph={{
          type: 'website',
          locale: 'en_IE',
          url: config.siteUrl,
          site_name: config.siteName,
        }}
        twitter={{
          handle: '@galllery',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <ZeiqProvider value={{ theme }}>
        <NextThemeProvider attribute="class" defaultTheme="light">
          <ThemeProvider theme={theme}>
            <StoreProvider store={reduxStore}>
              <ApolloProvider client={apolloClient}>
                <SimpleReactLightbox>
                  <Component {...pageProps} />
                </SimpleReactLightbox>
              </ApolloProvider>
            </StoreProvider>
          </ThemeProvider>
        </NextThemeProvider>
      </ZeiqProvider>
    </>
  );
}

export default withReduxStore(MyApp);
